<template>
	<w-dialog
		v-model="showDialog"
		:max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'"
		:title="$t('documents.rename', { type: itemType })"
		@ready="onDialogReady"
		@close="close"
	>
		<v-layout row>
			<v-text-field
				ref="nameInput"
				v-model.trim="name"
				color="primary"
				:label="$t('name')"
				maxlength="191"
				:rules="[rules.validName, rules.uniqueName]"
				@keyup.enter="save"
			/>
		</v-layout>

		<template v-slot:actions>
			<v-spacer />
			<w-btn-save :disabled="!isValid || !isNameUnique" @click="save" />
		</template>
	</w-dialog>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import { mapMutations } from "vuex";

export default {
	name: 'RenameDialog',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		},
		items: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	data: function () {
		return {
			name: '',
			showDialog: true,
			rules: {
				validName: value => {
					const regexp1 = new RegExp(/^[^/\\|:?<>*"]+$/, 'i')
					const regexp2 = new RegExp(/^[ \t]*$/, 'i')
					let result = true
					if (!value) {
						result = this.$t('documents.empty_item_name')
					} else if (!regexp1.test(value)) {
						result = this.$t('documents.bad_item_name')
					} else if (regexp2.test(value.trim())) {
						result = this.$t('documents.empty_item_name')
					}
					return result
				},
				uniqueName: () => {
					let result = true
					if (!this.isNameUnique) {
						if (this.model.type == 'folder') {
							result = this.$t('documents.rename_folder_same_name')
						} else {
							result = this.$t('documents.rename_document_same_name')
						}
					}
					return result
				}
			}
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		parentFolder: function () {
			return this.model ? this.model.parent : null
		},
		isValid: function () {
			return this.name.trim().length > 0
		},
		itemType: function () {
			let result = null
			if (this.model.type === 'folder') {
				result = this.$t('documents.types.folder')
			} else {
				result = this.$t('documents.types.file')
			}
			return result
		},
		isNameUnique: function () {
			let result
			if (this.model.is_folder) {
				result = !this.parentFolder.folders.find(folder => folder.name === this.name && folder.id !== this.model.id)
			} else {
				const nameWithExtension = this.getValidDocumentName(this.model.name, this.name)
				if (this.parentFolder) {
					result = !this.parentFolder.documents.find(doc => doc.name === nameWithExtension && doc.id !== this.model.id)
				} else {
					result = this.items.length > 0 ? !this.items.find(doc => doc.name === nameWithExtension && doc.id !== this.model.id) : true
				}
			}
			return result
		},
		nodes: function () {
			return !Array.isArray(this.value) && this.value ? [this.value] : this.value
		},
	},
	created () {
		this.setPreventKeyboardKeys([
			this.keyboard.arrow.left,
			this.keyboard.arrow.right,
			this.keyboard.arrow.up,
			this.keyboard.arrow.down,
		])
		this.name = this.model.name
	},
	destroyed: function () {
		this.setPreventKeyboardKeys([])
		this.close()
	},
	methods: {
		...mapMutations('documents/preview', ['setPreventKeyboardKeys']),
		onDialogReady () {
			this.$nextTick(() => {
				this.$refs.nameInput.focus();
			})
		},
		close: function () {
			this.showDialog = false
			this.$emit('close')
		},
		save: function () {
			if (this.isValid) {
				const newName = this.model.is_folder ? this.name : this.getValidDocumentName(this.model.name, this.name)
				const updateAction = this.model.is_folder ? this.service.updateFolder : this.service.updateFolderDocument
				const folderId = this.parentFolder ? this.parentFolder.id : this.model.folder_id
				const vendorId = this.vendorId ? this.vendorId : this.model.vendor_id
				updateAction(vendorId, folderId, this.model, null, { name: newName })
					.then(() => {
						this.model.name = newName
						const message = this.model.is_folder ? 
							this.$tc('documents.folder_updated', this.nodes.length) : 
							this.$tc('documents.document_updated', this.nodes.length)
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, message)
					})
					.finally(() => {
						this.close()
					})
			}
		},
		getValidDocumentName: function (oldDocumentName, newDocumentName) {
			let result = newDocumentName
			if (oldDocumentName && newDocumentName) {
				const oldExtension = oldDocumentName.indexOf('.') != -1 ? oldDocumentName.split('.').pop() : null
				const newExtension = newDocumentName.indexOf('.') != -1 ? newDocumentName.split('.').pop() : null
				const newName = newDocumentName.substring(0, newDocumentName.length - (newExtension ? newExtension.length + 1 : 0))
				if (!newExtension && oldExtension) {
					result = newName + '.' + oldExtension
				}
			}
			return result
		}
	}
}
</script>
